import React, { useContext, useState, useCallback } from 'react';
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';
import 'firebase/firestore';

import { GameContext } from './Game';
import { FirebaseContext } from './utils/firebase';

const TARGET_WIDTH = 7.27;
const BUTTON_HEIGHT = 2;

const Label = styled.div`  
  align-items: center;
  text-align: left;  
  top: 8vh;  
  left: 1vw;
  color: #777;
  position: absolute;
`;

const StyledList = styled.select`
  width: ${TARGET_WIDTH}vw;
  position: absolute;  
  color: #777;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");  
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 1vmin;
  padding: 1vmin;
  padding-right: 0.7vmin;
`;

const StyledLimitList = styled(StyledList)`
  top: 11vh;
  left: 1vw;
`;

const StyledPlayerList = styled(StyledList)`
  top: 15vh;
  left: 1vw;
`;

const RemoveButton = styled.div`
  top: 19vh;
  left: 1vw;
  position: absolute;  
  width: ${TARGET_WIDTH}vw;  
  min-height: ${BUTTON_HEIGHT}vh;
  corder: 1 slid grey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.1vw;
  text-align: center;
  color: #777;
  cursor: pointer;  
  &:hover {
    background-color: #F1F1F1
  }
`;

const PlayerAdmin = () => {
  
  const game = useContext(GameContext);
  const firebase = useContext(FirebaseContext);
  const analytics = firebase.analytics();
  const [selectedPlayer, setSelectedPlayer] = useState("");

  const updateTimeLimit = useCallback((value) => {
    if(game.host === game.currentUser) {
      console.log("updating time limit", value);      
      const gameRef = firebase.firestore().collection(`games`).doc(game.gameID)

      gameRef.set({    
        timeLimit: Number(value),
        updatedAt: new Date().toISOString()               
      }, {merge: true})
      .then(res => {
        console.log("timeLimit updated");  
        analytics.logEvent('timeLimit updated', {gameID:game.gameID, timeLimit: value});
      })
      .catch(error => {
        // Handle the error
      })  

    }  
  }, [firebase, analytics, game.gameID, game.currentUser, game.host])
  
  const handleRemove = useCallback(() => {
    if(game.host === game.currentUser) {
      console.log("current user is host");
      console.log('removing player', selectedPlayer)
      const gameRef = firebase.firestore().collection(`games`).doc(game.gameID)
      
      let players = Object.assign({},game.players);      
      delete players[selectedPlayer]; 

      gameRef.get()
      .then(gameAll => {
        const data = gameAll.data();
        //console.log(players);
        return gameRef.set({    
          ...data,    
          players: players,
          updatedAt: new Date().toISOString()       
        })
      })
      .then(res => {
        console.log("game players updated");  
        analytics.logEvent('removed_player', {gameID:game.gameID, removed: selectedPlayer, players});
      })
      .catch(error => {
        // Handle the error
      })  
    } else {
      console.log("current user is not host");
    }
  }, [firebase, analytics, game.host, game.gameID, game.currentUser, game.players, selectedPlayer])

  return (
    <div>
      <ReactTooltip multiline={true} effect="solid"/>
      <Label data-tip="Var veikt divas darbības:<br/>1. nomainīt laika limitu, pēc kura beigām visiem spēlētājiem parādīsies uzraksts ka spēle beigusies.<br/>limitu var mainīt jebkurā laikā, arī pēc tam kad tas ir sasniegts. Spēlei no tā nekas nenotiek.<br/><br/>2. izmest spēlētāju no spēles. <br/>Tiesa spēlētajs uzreiz pievienosies atpakaļ, ja viņam būs joprojām atvērts links.">Komandēt</Label>
      <StyledLimitList
        value={game.timeLimit}
        onChange={e => updateTimeLimit(e.currentTarget.value)}
      >
        <option value={-1}>Bez limita</option>
        <option value={1}>1 stunda</option>
        <option value={2}>2 stundas</option>
        <option value={3}>3 stundas</option>
        <option value={4}>4 stundas</option>
        <option value={5}>5 stundas</option>        
      </StyledLimitList>
      <StyledPlayerList
        value={selectedPlayer}
        onChange={e => setSelectedPlayer(e.currentTarget.value)}
      >
        <option value={""}>Spēlētājs</option>
        {
          // Object.values(game.players)
          // .sort((p1,p2) => {
          //   return p1.number - p2.number;  
          // })
          game.playersSorted.map(p => {            
            return <option key={p.uid} value={p.uid}>{p.name}</option>                                    
          })
        }
      </StyledPlayerList>
      { selectedPlayer && selectedPlayer !== game.host && (
        <RemoveButton onClick={handleRemove}>
          Izdzīt
        </RemoveButton>
      )}
      
    </div>    
    );
}

export default PlayerAdmin;