import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import CornerLogo from './CornerLogo';

const RulesText = styled.div`
  color: #777;
  font-size: 1.5vw;
  left: 1vw;
  top: 5vh;
  position: absolute;  
  width: 60vw;    

  a {
    color: inherit
  }
`;

const Rules = () => {
  
  return (
    <div>      
      <CornerLogo/>
      <RulesText>
        1. Šī spēle ir balstīta uz Cards Against Humanity, viņi ar to ir ok. Skatīt <a href="https://cardsagainsthumanity.com/">CaH FAQ</a>. Ja vēlaties varat izplatīt tālāk ar Creative Commons BY-NC-SA 2.0 <a href="https://creativecommons.org/licenses/by-nc-sa/2.0/">licenci</a>. <br/>
        2. Spēle saucas "Cehs Pret Cilvēci", noteikumi lasāmi <Link style={{ color: 'inherit'}} to="/noteikumi">šeit</Link>.<br/>
        3. Rotaļa adaptēta latviešu valodā ar <a href="http://cehs.lv">Cehs.lv</a> mākslīgā intelekta laboratorijas palīdzību.<br/>
        4. Viss šeit ir joks, spēle, izlikšanās, teātris, humors, parodija un tīra izmantojamlabgriba. <br/>
        5. Naidpilnu dusmupastu gaidīsim uz <a href="mailto:cehs.live@protonmail.com">cehs.live@protonmail.com</a>.<br/>        
        6. Karstākais Cehs Pret Cilvēci jaunumu apskats atrodams vīterītī <a href="https://twitter.com/CehsLive" target="_new">twitter.com/CehsLive</a>
      </RulesText>
    </div>
  );
}


export default Rules;