import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import CornerLogo from './CornerLogo';

const RulesText = styled.div`
  color: #777;
  font-size: 1.5vw;
  left: 1vw;
  top: 5vh;
  position: absolute;  
  width: 60vw;    
`;

const Rules = () => {
  
  return (
    <div>      
      <CornerLogo/>
      <RulesText>
        1. Šī spēle ir balstīta uz Cards Against Humanity, sk <Link style={{ color: 'inherit'}} to="/atruna">atrunu</Link>. Viss štokos.<br/>
        2. Spēle saucas "Cehs Pret Cilvēci", bet ja vēlaties, jūs varat to saukt par savu kuci.<br/>
        3. Spēlēt var no 1-10 spēlētāji, tiesa spēle ar mazāk kā trīs cilvēkiem sanāks padumja.<br/>  
        4. Spēlētāji var pievienoties spēlei atverot tās adresi pārlūkā. Nokopē un iedali draugiem.<br/>
        5. Katram spēlētājam jāpaņem aptuveni septiņas kārtis no "atbildes" kaudzītes un jāliek savā "rokā", kas atrodas ekrāna apakšā. Tur esošās kārtis ir redzamas tikai attiecīgajam spēlētājam. Kad kāda kārts tiek izspēlēta, tā jāņem jauna vietā.<br/>
        6. Gājiens sākas ar to ka spēlētājs pagriež kārti (noklikšķinot) "jautājumi" kaudzītē. <br/> 
        7. Pēc tam pārējiem spēlētājiem katram jāizvēlas kārts kas visgroteski smieklīgāk iederētos un jāliek tā uz viena no lauciņiem ekrāna vidū. <br/>        
        8. Kad visi ir uzlikuši, tad gājiena vadītājs nolasa jautājuma kārti un pa vienai apgriež uz galda esošās atbildes kārtis. Jautājuma kārts ir jāizlasa skaļi pirms katras kārts atvēršanas. Kārtis pagriež klikšķinot uz tām.<br/>
        9. Kad visas kombinācijas izlasītas, gājiena vadītājs izvēlas uzvarējošo kārti. Uzvarētājs noliek jautājuma kārti ekrāna augšpusē, lauciņā ar savu vārdu. Gājiena vadītājam jānovāc laukums un jāsaliek izspēlētās kārtis atmetnē.<br/>
        10. Nākošais spēlētājs pēc kārtas kļūst par nākošā gājiena vadītāju. Šis ciklis jātkārto līdz beidzas kārtis vai enerģija. Tiesa ja beidzas kārtis vienmēr var spēlēt jaunu partiju.<br/>
        11. Uzvar spēlē tas, kam ir izdevies savākt visvairāk jautājuma kāršu. <br/>
        12. Vārdu var mainīt no "spēlētājs N" uz kaut ko mīlīgāki nospiežot uz tā ekrāna apakšā.<br/>
        13. Dažiem jautājumiem ir nepieciešamas divas vai trīs atbildes kārtis. Tādos gadījumos tās jāliek kaudzītē, pirmo kārti liekot kā pirmo. <br/>
        14. Ja kāda kārts kas ir rokā nepatīk vai nav īsti saprotama, to var atmest izlietotajās un paņemt vietā jaunu. Spēles mērķis ir jautrība, nevis strikta noteikumu ievērošana.<br/>
        15. Nav paredzēts šo spēle spēlēt bez paralēlas videokonferences. Sazvani draugus kādā nebūt zūmskaipā, iedali linku uz spēli un asumiņš var sākties.<br/>
        16. Nedrīkst apvainoties uz jebkādām sensitīvi-seksuāli-baisīgām kombinācijām, kas rodas spēles gaitā. Jokot drīkst par visu, ja vien tas ir smieklīgi. <br/>
        17. Nemīz - PĀRĀK traku kāršu nav.<br/>        
      </RulesText>
    </div>
  );
}


export default Rules;