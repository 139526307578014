import React from 'react';
import styled from 'styled-components';

const TutorialText = styled.div`
  color: #777;
  font-size: 1.5vmin;      
  text-decoration: strong;
  a {
    color: inherit
  }
`;

const StyledTutorialEditName = styled.div`
  position: absolute;
  top: 3vh; 
  left: 2vw
`;

const StyledTutorialHand = styled.div`
  position: relative;
  top: 7vh;
  left: 27vw;
  width: 30vw;
`;

export const TutorialEditName = () => {
  
  return (
    <StyledTutorialEditName>
      <TutorialText>
        ^^^^ nospiežot uz vārda var to izmainīt. 
      </TutorialText>
    </StyledTutorialEditName>
  );
}

export const TutorialHand = () => {
  
  return (
    <StyledTutorialHand>
      <TutorialText>
        Šeit ir kārtis kas ir tavā rokā, tās redzi tikai Tu.<br/>
        <br/>
        Ievelc šeit kārtis no "Atbildes" kaudzītes.<br/>
        <br/>
        Un izlasi <a href="/noteikumi" target="_new">noteikumus</a>!
      </TutorialText>
    </StyledTutorialHand>
  );
}

