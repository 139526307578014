import React from 'react';
import { Router } from "@reach/router"

//import logo from './cehlogo.png';

import FirebaseProvider from './utils/firebase';
import Main from './Main';
import Game from './Game';
import Rules from './Rules';
import Disclaimer from './Disclaimer';
import NotFound from './NotFound';

import './App.css';

const App = () => {
    
  return (
    <FirebaseProvider> 
      <Router>
        <Main path="/"/>        
        <Game path="/spele/:gameID"/>
        <Rules path="/noteikumi"/>
        <Disclaimer path="/atruna" />
        <NotFound default />
      </Router>  
    </FirebaseProvider>
  );
};

export default App;