import React from 'react';
import styled from 'styled-components';

import PlayerInfo from './PlayerInfo';
import { TutorialEditName, TutorialHand } from './Tutorial';
import Card from './Card';

const HAND_LEFT = 13;
const HAND_HEIGHT = 25;
const HAND_TOP = 74.5;
const HAND_WIDTH = 84;
const HAND_CARD_WIDTH = 7;
const HAND_GAP = 1.5;

const HandContainer = styled.div`
  position: absolute;
  left: ${HAND_LEFT}vw;
  top: ${HAND_TOP}vh; 
  width: ${HAND_WIDTH}vw;  
  min-height: ${HAND_HEIGHT}vh;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
`;

const Hand = ({cardsHand, hand, onDragEnd, onDrag}) => {

  if(!cardsHand.length) {
    return (
      <HandContainer>
        <PlayerInfo/> 
        <TutorialEditName/>
        <TutorialHand/>
      </HandContainer>
      )
  } else {
    return (    
      <HandContainer>        
        <PlayerInfo/> 
        {cardsHand.map((card, index)  => {
          const isDragging = hand.draggedIndex === index;        
          const left = hand.dragOrder.indexOf(index) * (HAND_CARD_WIDTH + HAND_GAP);
          const draggedLeft = hand.order.indexOf(index) * (HAND_CARD_WIDTH + HAND_GAP);
          
          return (
            <Card
              key={card.key}
              cardKey={card.key}
              cardsIndex= {index}
              onDrag={onDrag}
              onDragEnd={onDragEnd}
              isDragging={isDragging}            
              isTarget={false}
              draggedLeft={draggedLeft}            
              left={left}
              data={card}            
            />          
          );
        })}
      </HandContainer>
    )
  }

}

export default Hand;