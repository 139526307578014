import React from 'react';
import styled from 'styled-components';

import CornerLogo from './CornerLogo';

const NotFoundText = styled.div`
  color: #777;
  font-size: 1.5vw;
  left: 1vw;
  top: 5vh;
  position: absolute;  
  width: 60vw;    

  a {
    color: inherit
  }
`;

const Rules = () => {
  
  return (
    <div>      
      <CornerLogo/>
      <NotFoundText>
        <b>404</b> nav šādas sadaļas. <br/>
        Vēlies par to parunāt? <br/>
        Laipni lūgti <a href="https://www.twitter.com/CehsLive">izteikties publiski</a>!
      </NotFoundText>
    </div>
  );
}


export default Rules;