import React from 'react';
import styled from 'styled-components';
//import { Link } from '@reach/router';

import CornerLogo from './CornerLogo';
import CornerTwitter from './CornerTwitter';
import Generator from './Generator';



const Disclaimer = styled.a`
  top: 4vh;
  left: 1vw;
  position: absolute;
  text-decoration: none;
  color: #777;
  :hover {
    text-decoration: underline;
  }
`;


const Main = () => {
  //<Link to="/spele/WhDCIUGJrPhMic6pnIXi">Spēle WhDCIUGJrPhMic6pnIXi</Link>
  return (
    <div>      
      <CornerLogo/>
      <CornerTwitter/>
      <Disclaimer href="/atruna" target="new">Atruna</Disclaimer>
      <Generator/>
    </div>
  );
}


export default Main;