import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from "react-tooltip";

const AvailableDecksBox = styled.div`  
  left: 30vw;  
  top: 14vh;
  width: 40vw;
  height: 25vh;
  border: 0.2vw solid grey;
  position: absolute;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;    
`;

const DecksTitle = styled.div`  
  height: 20%;
  font-size: 1.8vw;
  margin-bottom: 0.5vh;
  text-align: center;
`;

const AvailableDeckBox = styled.div`
  border: 0.1vw dashed grey;
  display: flex;
  flex-direction: column;    
  margin-bottom: 1vh;
  margin-left: 1vh;
  margin-right: 1vh;
  padding: 0.3vmin;
`;

const DeckRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.1vh;
  margin-bottom: 0.1vh;
`;

const DeckInfoBox = styled.div`
  width: 27vw;
`;

const PromptCountBox = styled.div`  
  background-color: #c7cdd6;  
  padding-left: 0.1vw;
  padding-right: 0.1vw;
  border: 0.1vw solid grey;
  margin-left: 0.5vw;
`;

const AnswersCountBox = styled.div`  
  background-color: #fff;  
  padding-left: 0.1vw;
  padding-right: 0.1vw;
  border: 0.1vw solid grey;
  margin-left: 0.5vw;
`;
//: '#fff',

const CardCountBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const CardCount = ({deck}) => {
  return (
    <CardCountBox>
      <PromptCountBox>{ deck.prompts }</PromptCountBox> 
      <AnswersCountBox>{ deck.answers }</AnswersCountBox>
    </CardCountBox>
  )
}


const SelectionsBox = styled.div`
  width: 20vw;  
  font-size: 1.2vw;  
  text-align: left;
  margin-left: 1vh;
`;

const ReplaceLabel = styled.div`  
  margin-left: 34vw;  
  font-size: 1.2vw;
  margin-bottom: 0.4vh;   
`;

const ReplaceCheckbox = styled.div`      
  position: absolute;
  right: 3vw;
`;

const Selections = ({cards}) => {

  return (
    <SelectionsBox>
      Jautājumu kārtis kopā: <b>{cards.prompts}</b> <br/>
      Atbilžu kārtis kopā: <b>{cards.answers}</b>
    </SelectionsBox>
  )
}

const AvailableDecks = ({decks, setDecks}) => {

  const [cards, setCards] = useState({
    prompts: 0,
    answers: 0
  });

  useEffect(() => {
    let prompts = 0;
    let answers = 0;
    Object.values(decks).forEach((deck) => {
      if(deck.selected) {
        prompts = prompts + deck.prompts;
        answers = answers + deck.answers;
      }      
      if(deck.selected && deck.replace) {
        prompts = prompts - deck.prompts;
        answers = answers - deck.answers;
      }
    });
    setCards({
      prompts,
      answers
    })

  }, [decks, setCards]);

  const handleClick = useCallback((id, e) => {    
    
    let updatedDecks = Object.assign({}, decks);    
    updatedDecks[id].selected = !decks[id].selected;
    
    setDecks(updatedDecks);
  }, [decks, setDecks]);

  const handleReplace = useCallback((id, e) => {    
    
    let updatedDecks = Object.assign({}, decks);    
    updatedDecks[id].replace = !decks[id].replace;
    
    setDecks(updatedDecks);
  }, [decks, setDecks]);

  return (
    <AvailableDecksBox>       
      <ReactTooltip multiline={true}/>
      <DecksTitle>Izvēlieties kāršu pakas</DecksTitle>
      <AvailableDeckBox>
        {Object.values(decks).filter(deck => deck.base === true).map(deck => {
          return <DeckRow key={deck.id}>
              <DeckInfoBox>
                <label >
                  <input type="radio" value={deck.id} checked={deck.selected} onChange={(e) => handleClick(deck.id, e)}/>
                  {deck.name}
                </label>
              </DeckInfoBox>
              <CardCount deck={deck}/>
            </DeckRow>
        })}
      </AvailableDeckBox>
      <ReplaceLabel data-tip="Aizstāt attiecīgu skaitu kāršu bāzes pakā<br/>ar kārtīm no konkrētās papildpakas,<br/>tādā veidā nepalielinot kopējo kāršu skaitu.">Aizstāt?</ReplaceLabel>
      <AvailableDeckBox>
        {Object.values(decks).filter(deck => deck.base === false).map(deck => {
            return <DeckRow key={deck.id}>
                <DeckInfoBox>
                  <label>
                    <input type="checkbox" value={deck.id} checked={deck.selected} onChange={(e) => handleClick(deck.id, e)}/>
                    {deck.name}
                  </label>
                </DeckInfoBox> 
                <CardCount deck={deck}/>
                <ReplaceCheckbox>
                  <input 
                    type="checkbox" 
                    value={deck.id} 
                    checked={deck.replace} 
                    onChange={(e) => handleReplace(deck.id, e)} 
                    disabled={!deck.selected}/>
                </ReplaceCheckbox>
              </DeckRow>
          })}
      </AvailableDeckBox>  
      <Selections cards={cards}/>
      
    </AvailableDecksBox>
  )  
}

export default AvailableDecks;