import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';
import 'firebase/firestore';
import EditableLabel from './utils/react-inline-editing';

import './GameInfo.css';
import CornerLogo from './CornerLogo';
import CornerTwitter from './CornerTwitter';
import TimeCounter from './TimeCounter';
import { FirebaseContext } from './utils/firebase';
import { GameContext } from './Game';

const GameInfoBar = styled.div.attrs(props => ({
  
}))`  
  align-items: center;
  text-align: left;  
  top: 1vh;  
  left: 12vw;
  color: #777;
  position: absolute;
  font-size: 1.8vw;  
`;

const Rules = styled.a`
  top: 4vh;
  left: 1vw;
  position: absolute;
  text-decoration: none;
  color: #777;
  :hover {
    text-decoration: underline;
  }
`;

const Ver = styled.div`
  bottom: 1vh;
  right: 0.8vw;
  position: absolute;
  text-decoration: none;
  color: #777;
  font-size: 1vmin;
`;

const GameInfo = () => {

  const game = useContext(GameContext);
  const firebase = useContext(FirebaseContext);
  const analytics = firebase.analytics();

  const handleFocusOut = useCallback((text)=>{
    //console.log("handleChange game name")
    const gameRef = firebase.firestore().collection(`games`).doc(game.gameID)

    gameRef.set({    
      name: text        
    }, {merge: true})
    .then(res => {
      //console.log("game name updated");  
      analytics.logEvent('game_name_changed_in_game', {gameID:game.gameID, playerID: game.currentUser, playerName:text});
    })
    .catch(error => {
      // Handle the error
    })      
  },[game, firebase, analytics]);  

  return (
    <div>
      <CornerLogo/>
      <Rules href="/noteikumi" target="_new">Noteikumi</Rules>
      <GameInfoBar>  
        { game.host === game.currentUser ? <EditableLabel inputClassName={"game-input-style"} labelClassName={"game-label-style"} text={game.name} onFocusOut={handleFocusOut}/> : game.name }               
      </GameInfoBar>
      <CornerTwitter/>
      { game.timeLimit !== -1 ? <TimeCounter/> : null }  
      <Ver>{process.env.REACT_APP_VERSION}</Ver>
    </div>    
    );
}
//<small>(izšķirtspēja { window.innerWidth } x { window.innerHeight })</small>

export default GameInfo;