import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from '@reach/router';
import { FirebaseContext } from './utils/firebase'

import useAuth from './utils/useAuth';
import GameInfo from './GameInfo';
import Table from './Table';
import PlayerAdmin from './PlayerAdmin';

const GameContext = createContext(null)
export { GameContext }

const Game = () => {

  const params = useParams();
  const firebase = useContext(FirebaseContext);
  const analytics = firebase.analytics();
  const [loaded, setLoaded] = useState(false)
  const [state, setState] = useState({    
    players: {}    
  });      
  const [playersSorted, setPlayersSorted] = useState([]);
  const uid = useAuth();

  useEffect(() => {
    let hostPlayer;
    let playersSorted = Object.values(state.players)    
    .filter(p => {
      if(p.uid === state.host) {
        hostPlayer = p;
        return false;
      } else {
        return true;
      }
    })
    .sort((p1, p2) => {         
      if (p1.uid < p2.uid) {
        return -1; 
      } else {
        return 1;
      } 
    });

    if(hostPlayer) {
      playersSorted.unshift(hostPlayer); //add host in the 0th position of the sorted array
    }
    

    //console.log("playersSorted",playersSorted, state.host);
    setPlayersSorted(playersSorted);

  }, [state.players, state.host, setPlayersSorted]);

  useEffect(() => {        
    if(params.gameID) {
      const gameRef = firebase.firestore().collection(`games`).doc(params.gameID)
      const unsubscribe = gameRef.onSnapshot(game => {
        //console.log('snapshot game',game.data());
        if (!game.exists) {
          analytics.logEvent('game_not_found', {gameID:params.gameID});
          setState({            
            name:"no game",
            players: {}
          });                  
        } else {
          analytics.logEvent('game_found', {gameID: params.gameID});
          setState({      
            host: game.data().host,        
            name:game.data().name,
            players:game.data().players || {},
            timeLimit: game.data().timeLimit || -1,
            generatedAt: game.data().generatedAt
          });                       
          setLoaded(true);
        }
      });
      return () => {
        //console.log("unsubscribed from game updates");
        unsubscribe();
      };  
    }   
  }, [firebase, params.gameID, setLoaded, analytics]);

  //add currentUser to players if not yet added
  useEffect(() => {    
    if(uid && loaded && !state.players[uid]) {
      console.log('currentUser not found among players, adding')
      const gameRef = firebase.firestore().collection(`games`).doc(params.gameID)

      let players = state.players;
      let maxNumber = 0;
      Object.values(state.players).forEach(player => {
        if(player.number > maxNumber) {
          maxNumber = player.number;
        }
      });
      const number = maxNumber + 1; //we don't want 0th player
      players[uid] = {
        uid: uid,
        name: "Spēlētājs "+number,
        number: number
      };
      
      gameRef.set({        
        players: players,
        updatedAt: new Date().toISOString()       
      }, {merge: true})

      .then(res => {
        //console.log("game updated");  
        analytics.logEvent('game_added_current_player', {gameID:params.gameID, player:uid, players});
      })
      .catch(error => {
        // Handle the error
      })      
    }
  },[firebase, state, uid, params, loaded, analytics])

  const providerValue = {
    gameID : params.gameID,
    players : state.players,
    name: state.name,
    currentUser: uid,
    host: state.host,
    playersSorted: playersSorted,
    timeLimit: state.timeLimit,
    generatedAt: state.generatedAt
  }
  if(loaded) {
    return (
      <div>
        <GameContext.Provider value={providerValue} >        
          <GameInfo/>
          { state.host === uid ? <PlayerAdmin/> : "" }
          <Table/>      
        </GameContext.Provider>
      </div>      
    )
  } else {
    return (<div> Loading ...</div>)
  }
  
}

export default Game;
