import React from 'react';
import styled from 'styled-components';

const StyledCounter = styled.div.attrs(props => ({
  style: {
    fontSize: props.isPlayerTarget ? '1.1vmin' : '0.8vmin',
  }
}))`
  height: 2vh;
  width: 1.5vw;
  color: #777;  
  z-index: 1999;
  bottom: 0.4vh;
  right: 0.2vw;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border: 0.1vmin solid grey;
 `;

 //font-size: 0.8vw;
 //left: Calc(${({left}) => left}vw - 1vw);
 //top: calc(${TARGET_HEIGHT}vh + ${({top}) => top}vh);

 const Counter = ({isPlayerTarget, number}) => {
  return (
    <StyledCounter isPlayerTarget={isPlayerTarget}>
        {number}
    </StyledCounter>
  )
 }

 export default Counter;