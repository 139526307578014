import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import logo from './cehlogo.png';

const StyledCornerLogo = styled.img`
  width: 8vw;
  left: 1vw;
  top: 1vh;
  position: absolute;  
`;


const CornerLogo = () => {
  return(
    <Link to="/"><StyledCornerLogo src={logo}/></Link>
  )
}


export default CornerLogo;