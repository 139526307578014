import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { range, inRange } from 'lodash';

import { FirebaseContext } from './utils/firebase'
import { GameContext } from './Game'

import Hand from './Hand';
import Target from './Target';

const MAX = 10;

//const TARGET_WIDTH = 9.18;
const TARGET_WIDTH = 7.27;
const TARGET_HEIGHT = 17.55;

const PROMPTS_TOP = 25;
const BUTTON_HEIGHT = 2;
const ANSWERS_TOP = PROMPTS_TOP+3+TARGET_HEIGHT;
const DISCARDS_TOP = PROMPTS_TOP+2*(3+TARGET_HEIGHT);
const DISCARD_TABLE_TOP = PROMPTS_TOP+3*(3+TARGET_HEIGHT);
const SHUFFLE_DISCARDS_TOP = DISCARD_TABLE_TOP + BUTTON_HEIGHT + 1;
const DECK_LEFT = 1;

const PLAYERS_TOP = 5;
const PLAYERS_LEFT = 10; 
const PLAYERS_GAP = 1;

const PLAYER_COUNT = 10;
const PLAYER_LEFT = range(PLAYER_COUNT);
PLAYER_LEFT.forEach((player, index) => {
  PLAYER_LEFT[index] = PLAYERS_LEFT + index*(PLAYERS_GAP + TARGET_WIDTH);
})

const PLAYS_TOP = 39;
const PLAYS_LEFT = 15; 
const PLAYS_GAP = 1;

const PLAY_COUNT = 9;
const PLAY_LEFT = range(PLAY_COUNT);
PLAY_LEFT.forEach((play, index) => {
  PLAY_LEFT[index] = PLAYS_LEFT + index*(PLAYS_GAP + TARGET_WIDTH);
})

const HAND_LEFT = 13;
const HAND_HEIGHT = 25;
const HAND_TOP = 74.5;
const HAND_WIDTH = 84;
const HAND_CARD_WIDTH = 7;

const findArea = (left, top, width, height, vw, vh) => {
  if(vw > left && vw < left+width && vh > top && vh < top+height) {    
    return true;
  } else {    
    return false;
  }

}

const DiscardTableButton = styled.div`
  position: absolute;
  left: ${DECK_LEFT}vw;
  top: ${DISCARD_TABLE_TOP}vh; 
  width: ${TARGET_WIDTH}vw;  
  min-height: ${BUTTON_HEIGHT}vh;
  corder: 1 slid grey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.1vw;
  text-align: center;
  color: #777;
  cursor: pointer;  
  &:hover {
    background-color: #F1F1F1
  }
`;

const ShuffleDiscardsButton = styled.div`
  position: absolute;
  left: ${DECK_LEFT}vw;
  top: ${SHUFFLE_DISCARDS_TOP}vh; 
  width: ${TARGET_WIDTH}vw;  
  min-height: ${BUTTON_HEIGHT}vh;
  corder: 1 slid grey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.1vw;
  text-align: center;
  color: #777;
  cursor: pointer;  
  &:hover {
    background-color: #F1F1F1
  }
`;



const Table = () => {
  const firebase = useContext(FirebaseContext);
  const analytics = firebase.analytics();
  const game = useContext(GameContext);
  const [deck, setDeck] = useState([]);
  const [cardsHand, setCardsHand] = useState([]);    
  const [shuffleStarted, setShuffleStarted] = useState(false);
  const cardsMaxHandArray= range(MAX);    

  const [hand, setHand] = useState({
    order: cardsMaxHandArray,
    dragOrder: cardsMaxHandArray, // items order while dragging
    draggedIndex: null
  });  

  useEffect(() => {
    const deckRef = firebase.firestore().collection(`games`).doc(game.gameID).collection("deck")
    const unsubscribe = deckRef.onSnapshot(snapshot => {
      //console.log('snapshot deck on table',snapshot.docs);
      if (!snapshot) {
        setDeck(l => [])
      } else {
        let cards = []
        snapshot.forEach(card => {
          //cards that are not owned by current player are not shown
          //cards that are being dragged by others or are in other hands
          const c = card.data();
          //console.log(!c.player,c.player === game.currentUser , !c.player || c.player === game.currentUser);
          //if(!c.player || c.player === game.currentUser) {
            cards.push({ key: card.id, ...c })  
          //}          
        })
        //console.log('cards',cards);

        const groupedByArea = cards.reduce( (acc, card) => {
          let area = card["area"];
          if (!acc[area]) {
            acc[area] = []
          } 
          acc[area].push(card);
          return acc;
        }, {});
        //console.log('cards',cards)
        //console.log('groupedByArea', groupedByArea);
        setDeck(l => groupedByArea);
        setCardsHand(l => cards.filter(card => card.area === "hand" && card.player === game.currentUser));                        
        if(shuffleStarted) {
          if(groupedByArea.answers && groupedByArea.answers.length > 0) {
            setShuffleStarted(false); //allow button to get enabled again only when answers are back
          }
        }
      }
     
    });
    
    return () => {
      console.log("unsubscribed from deck snapshot")
      unsubscribe();
    }
    
  }, [firebase, game.gameID, game.currentUser, shuffleStarted, setShuffleStarted]);

  const handleDiscardTableClick = useCallback(() => {     
    analytics.logEvent('table_discard', {gameID:game.gameID, player: game.currentUser});       
    firebase.firestore()
    .collection(`games`).doc(game.gameID).collection("deck")
    .where("areaType", "==", "play")
    .get()
    .then(cards => {      
      const currentAreaSize = (deck["discards"] && deck["discards"].length) || 0;
      let order = currentAreaSize;
      cards.forEach(card => {        
        order = order + 1;        
        card.ref.set({
          area: "discards",          
          areaType: "deck",
          frontVisible: false,
          order: order
        }, {merge: true})
        .then(res => {
          //console.log("card updated on dragEnd");  
        })
        .catch(error => {
          // Handle the error
        })
      });  
    }); 
    
  }, [firebase, analytics, game.gameID, game.currentUser, deck]);

  const handleShuffleDiscardsClick = useCallback(() => {     
    console.log("shuffle started");
    setShuffleStarted(true);
    analytics.logEvent('shuffle_discards', {gameID:game.gameID, player: game.currentUser});       
    firebase.firestore()
    .collection(`games`).doc(game.gameID).collection("deck")
    .where("area", "==", "discards")
    .where("prompt", "==", false)
    .get()
    .then(cards => {      
      //console.log("cards found to shuffle", cards.docs);
      function shuffle(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
      }

      const shuffledCards = shuffle(cards.docs);      
      //console.log("shuffledCards",shuffledCards);
      let order = 0;      
      return shuffledCards.forEach(card => {          
        order = order+1; 
        card.ref.set({
          area: "answers",          
          areaType: "deck",
          frontVisible: false,
          order: order
        }, {merge: true})
        .then(res => {
          //console.log("card shuffled and updated");  
        })
        .catch(error => {
          // Handle the error
        })
      });    
    })
    .then( done => {      
      console.log("shuffle finished");
    })  
    
  }, [firebase, analytics, game.gameID, game.currentUser]);

  //this matters only for cards in hand, to order them  
  const handleDrag = useCallback(({translation, id, position, cardKey}) => {            
    
    //convert drag position into vw and vh - responsive css units    
    let vh = (position.y/window.innerHeight)*100;    
    //no need to rearrange order if card is not on the hand anymore
    if(vh < HAND_TOP) {
      return
    }   
    //we delete length that card has moved with cards height and get 0,1 or 2 or ... n by rounding.
    //which is delta difference of position
    //const delta = Math.round(translation.y / HEIGHT);

    //if this card already exists in hand, we handle the shuffling 
    if(cardsHand.find( card => card.key === cardKey)) {
      
      const width_in_pixels = window.innerWidth/ HAND_CARD_WIDTH;
      const delta = Math.round(translation.x / width_in_pixels);
      //console.log('card exists in hand', width_in_pixels, delta);
      const index = hand.order.indexOf(id);    
      //dragOrder is order without current dragged id
      const dragOrder = hand.order.filter(index => index !== id);
      //if we drag too far at the end of the list, function stops
      if (!inRange(index + delta, 0, cardsHand.length)) {
        return;
      }
      //we add currently dragged id into new position in the dragOrder
      dragOrder.splice(index + delta, 0, id);
      
      setHand(state => ({
        ...state,
        draggedIndex: id,
        dragOrder
      }));
    } 
  }, [hand.order, cardsHand]);

  const inArea = useCallback((vw, vh) => {
    let area;
    if(findArea(DECK_LEFT, PROMPTS_TOP, TARGET_WIDTH, TARGET_HEIGHT, vw, vh)) {
      area = "prompts"
    } else 
    if(findArea(DECK_LEFT, ANSWERS_TOP, TARGET_WIDTH, TARGET_HEIGHT, vw, vh)) {
      area = "answers"
    } else
    if(findArea(DECK_LEFT, DISCARDS_TOP, TARGET_WIDTH, TARGET_HEIGHT, vw, vh)) {
      area = "discards"
    } else 
    if(findArea(HAND_LEFT, HAND_TOP, HAND_WIDTH, HAND_HEIGHT, vw, vh)) {
      area = "hand"
    } 
  
    if(!area) {        
      const player_targets_in_play = PLAYER_LEFT.slice(0,Object.keys(game.players).length);
      const found = player_targets_in_play.findIndex((left, index) => {      
      if(findArea(left, PLAYERS_TOP, TARGET_WIDTH, TARGET_HEIGHT, vw, vh)) {
        return true;
      } else {
        return false;          
      }       
      });
      //console.log(PLAYER_LEFT, Object.keys(game.players).length, player_targets_in_play, found);  
      if(found > -1) {
        area = "player_"+game.playersSorted[found].uid;
      }       
    }  
  
    if(!area) {  
      const found = PLAY_LEFT.findIndex((left, index) => {
        if(findArea(left, PLAYS_TOP, TARGET_WIDTH, TARGET_HEIGHT, vw, vh)) {
          return true;
        } else {
          return false;
        }
      });  
      if(found > -1) {
        area = "play"+String(found+1);
      }       
    }  
    
    //console.log(area);
    return area;
  },[game.players, game.playersSorted]);
	
  const handleDragEnd = useCallback((id, position, cardKey) => {
    
    let dropVw = (position.x/window.innerWidth)*100;
    let dropVh = (position.y/window.innerHeight)*100;
    //console.log('vw, vh',dropVw, dropVh)

    const area = inArea(dropVw, dropVh);
    const card = Object.values(deck).flat().find( c => c.key === cardKey);

    //if this card existed in hand already
    if(cardsHand.find( c => c.key === cardKey)) {
      //if card has been removed from hand, remove the gap
      setHand(state => ({
        ...state,
        order: state.dragOrder,
        draggedIndex: null
      }));
      
    }  

    //console.log('new and old areas', area, card.area)
    //if there is nothing to update, return
    if(!area || area === card.area) {
      return;
    }

    const cardRef = firebase.firestore().collection(`games`).doc(game.gameID).collection("deck").doc(cardKey);

    const currentAreaSize = (deck[area] && deck[area].length) || 0;
    const order = currentAreaSize + 1;  
    //console.log('drop card', dropVw, dropVh, order)
    let frontVisible = card.frontVisible;
    let player = null;
    let areaType = null;
    if(area.substring(0,6) === "player") {     
      frontVisible = false;
      areaType = "player";
    } else 
    if(area.substring(0,4) === "play") {
      frontVisible = false;
      areaType = "play";
    }  
    else if(area === "prompts" || area === "answers" || area === "discards") {      
      frontVisible = false;
      areaType = "deck";
    } else 
    if(area === "hand") {
      frontVisible = true;
      player = game.currentUser;
      areaType = "hand";      
    }
    analytics.logEvent('card_moved', {gameID:game.gameID, player: game.currentUser, card: cardKey, area: area});       

    const removeKey = ({ key, ...rest }) => rest
    cardRef.set({
      ...removeKey(card),
      area: area,
      order: order,
      frontVisible: frontVisible,
      player: player,
      areaType: areaType
    })
    .then(res => {
      //console.log("card updated on dragEnd");  
    })
    .catch(error => {
      // Handle the error
    })
  }, [deck, firebase, analytics, game.gameID, game.currentUser, cardsHand, inArea]);

  // let listToDisplay
  // if (deck === null) {
  //   listToDisplay = (<li>Loading game deck...</li>)
  // } else if (deck.length === 0) {
  //   listToDisplay = (<li>No deck found</li>)
  // } else {
  //   listToDisplay = Object.values(deck).flat().map(card => {
  //     //console.log('card',card);
  //     return (<li key={ card.key }>{ card.text } { String(card.frontVisible)} {card.hand}</li>)
  //   })
  // }

  // <ol>{listToDisplay}</ol>    
  //console.log('!deck.answers && !shuffleStarted',!deck.answers, !shuffleStarted);

  return (
    <div>
      <Target area="prompts" areaType="deckArea" top={PROMPTS_TOP} left={DECK_LEFT} caption="Jautājumi" cards={deck.prompts} onDrag={handleDrag} onDragEnd={handleDragEnd}/>
      <Target area="answers" areaType="deckArea" top={ANSWERS_TOP} left={DECK_LEFT} caption="Atbildes" cards={deck.answers} onDrag={handleDrag} onDragEnd={handleDragEnd}/>
      <Target area="discards" areaType="deckArea" top={DISCARDS_TOP} left={DECK_LEFT} caption="Izlietotās" cards={deck.discards} onDrag={handleDrag} onDragEnd={handleDragEnd}/>
      {        
       //Object.keys(game.players)
      //  .sort((uid1, uid2) => {         
      //     return game.players[uid1].number - game.players[uid2].number;
      //  })
      game.playersSorted.map((player, index) => {      
        // const player = game.players[uid];                 
        //const left = PLAYER_LEFT[player.number-1];        
        const left = PLAYER_LEFT[index];
         return (
          <Target 
            key={player.uid}
            area={"player_"+player.uid} 
            areaType="playerArea" 
            top={PLAYERS_TOP} 
            left={left} 
            cards={deck["player_"+player.uid]} 
            caption={player.name}  
            onDrag={handleDrag} 
            onDragEnd={handleDragEnd}
            />
         )
       }) 
      }
      {
        PLAY_LEFT.map((play_left, index) => {
          return (
            <Target 
              key={index}
              area={"play"+String(index+1)} 
              areaType="playArea" 
              top={PLAYS_TOP} 
              left={play_left} 
              cards={deck["play"+String(index+1)]} 
              staggered={true}  
              onDrag={handleDrag} 
              onDragEnd={handleDragEnd}/>
          )  
        })              
      }
      <DiscardTableButton onClick={handleDiscardTableClick}>Notīrīt galdu</DiscardTableButton>
      { !deck.answers && !shuffleStarted &&
            <ShuffleDiscardsButton onClick={handleShuffleDiscardsClick}>Sajaukt izlietotās</ShuffleDiscardsButton>
      }
      
      <Hand
        cardsHand={cardsHand}
        hand={hand}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
      />
    </div>    
    );
}


export default Table;