import React from 'react';
import styled from 'styled-components';

import logo from './twitterlogo.svg';

const StyledCornerTwitter = styled.img`
  width: 3vw;
  left: 96vw;
  top: 1vh;
  position: absolute;
  background-color: #657786;  
  &:hover {
    background-color: #1DA1F2;
  }
`;


const CornerLogo = () => {
  return(
    <a href="https://twitter.com/CehsLive" target="_new"><StyledCornerTwitter src={logo}/></a>
  )
}


export default CornerLogo;