import React, {useLayoutEffect, useState, useRef, useEffect} from 'react';

const AdaptiveText = ({children, textStyle, targetWidthRatio, targetHeightRatio, maxpx, maxvmin}) => {
  const ref = useRef(null);
  const [size, setSize] = useState({
    width: 0,
    height: 0
  });
  const [parentSize, setParentSize] = useState({
    width: 0,
    height: 0
  });
  
  const [style, setStyle] = useState({});
  const [decreaseOngoing, setDecreaseOngoing] = useState(false);
  const [increaseOngoing, setIncreaseOngoing] = useState(false);
  const [stopTheProcess, setStopTheProcess] = useState(false);


  //adjust child size after child is originally rendered or font size has changed  
  useEffect(() => {
    if(!ref.current) {      
      return;
    }
    
    // const w1 = Number(window.getComputedStyle(ref.current).getPropertyValue('width').slice(0,-2));
    // const w2 = ref.current.offsetWidth;
    
    // const h1 = Number(window.getComputedStyle(ref.current).getPropertyValue('height').slice(0,-2));
    // const h2 = ref.current.offsetHeight;
    // console.log('w1,w2, h1,h2',w1,w2,h1,h2);

    setSize({
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight      
    });    
    
  },[ref, children, setSize, style.fontSize])

  //adjust parent size whenever window resize happens
  useLayoutEffect(() => {    
    function updateParentSize() {
      //console.log('update parent size', ref.current.parentNode)
      if(ref.current && ref.current.parentNode) {      
        setParentSize({          
          width: ref.current.parentNode.offsetWidth,
          height: ref.current.parentNode.offsetHeight
        })
        //reset fitting
        setDecreaseOngoing(false);
        setIncreaseOngoing(false);    
        setStopTheProcess(false);    
      } else {
        //console.log('no parent current');
      }
      
    };
    window.addEventListener('resize', updateParentSize);    
    updateParentSize();
    return () => window.removeEventListener('resize', updateParentSize);
  }, [ref, setParentSize, setDecreaseOngoing, setIncreaseOngoing])

  //change font size until text fits inside parent
  useLayoutEffect(() => {
    //console.log('parent or child size changed', parentSize.width, parentSize.height, size.width, size.height, String(children));
    //console.log('width parent, child', parentSize.width, size.width, String(children));
    //console.log('height parent, child', parentSize.height, size.height, String(children));
    if(!stopTheProcess && parentSize.width !== 0 && parentSize.height !== 0 && size.width !== 0 && size.height !== 0) {
      let tooLarge = false;      
      const currentFontSize = Number(window.getComputedStyle(ref.current).getPropertyValue('font-size').slice(0,-2));

      // const paddingLeft = window.getComputedStyle(ref.current).getPropertyValue('padding-left') || 0;
      // const paddingRight = window.getComputedStyle(ref.current).getPropertyValue('padding-right') || 0;
      // console.log('padding left, right of child', paddingLeft, paddingRight, String(children));
      //child width is larger than parent width, so need to reduce font
      //magic number "1" is here to avoid some strange cases where child is 1px larger than parent, 
      //even when that is not actually the case
      if(size.width - 1 > parentSize.width) {
        //console.log("child is wider than parent, tooLarge=true", String(children));
        tooLarge = true;
      } 
      //child is higher than parent, reducing
      if(size.height -1 > parentSize.height) {
        //console.log("child is higher than parent, tooLarge=true", String(children));
        tooLarge = true;
      }

      //console.log(tooLarge, decreaseOngoing, increaseOngoing);

      //it is too large, and there is no ongoing increase. lets decrease!
      if(tooLarge && !increaseOngoing) {
        let newFontSize = currentFontSize - 1;
        if(newFontSize <= 1) {
          newFontSize = 1;
        }
        //console.log('decrease from to', currentFontSize, newFontSize);        
        setStyle({
          ...textStyle,
          fontSize: newFontSize,
          //color: "green"          
        });     
        setDecreaseOngoing(true);
        return 
      }      
      
      //it is not too large, and there is no decrease ongoing, lets increase!
      if(!tooLarge && !decreaseOngoing) {
        
        let newFontSize = currentFontSize + 1;        
        let min = window.innerWidth < window.innerHeight? window.innerWidth : window.innerHeight;
        let newFontSizeInVmin = (newFontSize/min)*100;
        //console.log('increase from to vmin maxvmin', currentFontSize, newFontSize, newFontSizeInVmin, maxvmin, children);        
        if(newFontSizeInVmin > maxvmin) {
          setStopTheProcess(true);
          return;          
        }
        if(newFontSize > maxpx) {
          setStopTheProcess(true);
          return;          
        }
        setStyle({
          ...textStyle,
          fontSize: newFontSize,          
          //color: "orange" 
        });     
        setIncreaseOngoing(true);
        return 
      }

      //we have increased it too much, move one step back
      if(tooLarge && !decreaseOngoing && increaseOngoing) {
        
        let newFontSize = currentFontSize -1;
        //console.log('increase step back from to', currentFontSize, newFontSize);        
        
        setStyle({
          ...textStyle,
          fontSize: newFontSize,          
          //color: "red" 
        });     
        setIncreaseOngoing(true);
        setStopTheProcess(true);
        return 
      }

      
    }  
  },[parentSize, size, setStyle, textStyle, targetWidthRatio, targetHeightRatio, children, decreaseOngoing, setDecreaseOngoing, increaseOngoing, setIncreaseOngoing, stopTheProcess, setStopTheProcess, maxvmin, maxpx]);

  return (
    <div ref={ref} style={{
      ...textStyle,
      ...style,
      position: "absolute",
      height: "auto"      
      }}>
      {children}
    </div>
  )
}


export default AdaptiveText;