import React, {useState, useContext, useEffect} from 'react';
import styled from 'styled-components';
import { GameContext } from './Game';



const StyledCounter = styled.div.attrs(props => ({
  style: {
    color: props.finishing ? 'red' : 'none',    
  }
}))`
  width: 15vw;
  left: 85vw;
  top: 2vh;
  position: absolute;  
  color: #777
`;

const FinishOverlay = styled.div`
  position: absolute; /* Sit on top of the page content */  
  width: 50vw; /* Full width (cover the whole page) */
  height: 20vh; /* Full height (cover the whole page) */
  top: 25vh;
  left: 25vw;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.2); 
  z-index: 2000;    
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10vmin;
`;



const calculateTimeLeft = (generatedAt, timeLimit) => {

  const startTime = new Date(generatedAt);  
  const endTime = new Date(startTime.getTime());
  const timeNow = new Date();
  endTime.setHours(startTime.getHours() + timeLimit);  
  const difference = endTime - timeNow;
  let timeLeft = {};
  // console.log(generatedAt, timeLimit, startTime.getHours());
  console.log(timeNow.toString(), endTime.toString(), difference);

  timeLeft = {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60)
  };

  return timeLeft;
};

const TimeCounter = () => {
  const game = useContext(GameContext);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(game.generatedAt, game.timeLimit));
  const [finishing, setFinishing] = useState(false);
  const [finished, setFinished] = useState(false);
  
  const messages = ["FIN","Spēles Beigas!","Beidzītis", "Finišs", "The End", "Конец"];
  const finishMessage = messages[Math.floor(Math.random() * messages.length)];

  useEffect(() => {
    if(!finished) {
    const timer = setTimeout(() => {      
      setTimeLeft(calculateTimeLeft(game.generatedAt, game.timeLimit));
      
     }, 60000);
     return () => clearTimeout(timer);
    }    
  }, [finished, timeLeft, setTimeLeft, game.timeLimit, game.generatedAt]);

  //force update if time limit is changed
  useEffect(() => {    
    if(!finished) {
      setTimeLeft(calculateTimeLeft(game.generatedAt, game.timeLimit));    
    }    
  }, [finished, setTimeLeft, game.timeLimit, game.generatedAt]);
  
  useEffect(() => {
    console.log("finishing check effect runs", timeLeft)
    if(timeLeft && timeLeft.hours === 0 && timeLeft.minutes < 11) {
      setFinishing(true);
    } else {
      setFinishing(false);
    }  
    
    if(timeLeft && timeLeft.hours <= 0 && timeLeft.minutes <= 0) {        
      setFinished(true);
    } else {
      setFinished(false);
    }
  },[timeLeft, setFinished, setFinishing]);

  
  if(!finished) {
    return(
      <StyledCounter finishing={finishing}>    
        {"Spēles laiks "}
        {timeLeft.hours}h
        {timeLeft.minutes}m                  
      </StyledCounter>
    )
  } else {    
    return(
      <FinishOverlay>
        {finishMessage}
      </FinishOverlay>
    )
  }

}


export default TimeCounter;