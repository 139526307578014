import { useContext, useEffect, useState } from 'react'
import { FirebaseContext } from './firebase'
import 'firebase/auth';

const useAuth = () => {
  const firebase = useContext(FirebaseContext);
  const analytics = firebase.analytics();
  const [uid, setUID] = useState(null);
  useEffect(() => {
    if(!uid) {
      firebase.auth().signInAnonymously()
      .then(res => {
        const unsubscribe = firebase.auth().onAuthStateChanged( user => {
          if (user) {
            console.log('anonymous login successful',user.uid);
            setUID(user.uid);
            analytics.setUserId(user.uid);
          } else {
            console.log('user signed out');
            setUID(null);
            analytics.setUserId(null);
          }               
        });
        return () => {
          unsubscribe();
        }; 
      })
      .catch((error) => {
        console.log('error in auth', error);
      });
    }
  }, [uid, firebase, setUID, analytics]);

  return uid;
}

export default useAuth;