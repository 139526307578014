import React, {useCallback, useContext} from 'react';
import styled from 'styled-components';

import { FirebaseContext } from './utils/firebase'
import { GameContext } from './Game'
import AdaptiveText from './utils/AdaptiveText';

import Draggable from './Draggable'
import logo from './cehlogo.png';

//const CARD_WIDTH = 8.62;
const CARD_WIDTH = 7;
const CARD_HEIGHT = 16.9;
//const TARGET_WIDTH = 9.18;
const TARGET_WIDTH = 7.27;
const TARGET_HEIGHT = 17.55;

const CARD_MARGIN_TOP = (TARGET_HEIGHT-CARD_HEIGHT)/2;
const CARD_MARGIN_LEFT = (TARGET_WIDTH-CARD_WIDTH)/2;

const Frame = styled.div.attrs(props => ({
  style: {
    transition: props.isDragging ? 'none' : 'all 500ms',
    background: props.isPrompt ? '#c7cdd6' : '#fff',
    top: props.isTarget ? props.targetTop+'vh' : '4vh',
    left: props.isTarget ? CARD_MARGIN_LEFT+'vw' : 'calc(5vw + '+props.left+'vw)',
    position: props.isTarget ? 'absolute' : 'absolute',
    zIndex: props.order
  }
}))`
  width: ${CARD_WIDTH}vw;  
  user-select: none;
  height: ${CARD_HEIGHT}vh;  
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);  
  text-align: center;
  color: #777;
  display: flex;  
  
  justify-content: center;  
`;

//display: flex;
//background: #fff;
//left: calc(5vw + ${({left}) => left}px);  
//top : 5vh;
//z-index: ${({order}) => order};

const Border = styled.div`
  width: 100%;
  height: 100%;
  border: 0.3vw solid white;
  box-shadow: inset 0px 0px 0px 0.2vw grey;
  box-sizing: border-box; /* Include padding and border in element's width and height */
  align: relative;
  display: flex;
  align-items: center;
  justify-content: center;  
`;



const StyledLogo = styled.img`
  width: 5vw;
`;

const CardContent = ({frontVisible, text, isPrompt}) => {
  let text2;
  if(isPrompt) {
    text2 = text.replace(/LIETA/g, "_____")
  } else {
    text2 = text
  }
  if(frontVisible) {
    const textStyle = {
      paddingLeft: isPrompt ? "0.1vw": "0.1vw", 
      paddingRight: isPrompt ? "0.1vw": "0.1vw", 
      paddingTop: isPrompt ? "1vh": "2vh", 
      paddingBottom: isPrompt ? "1vh": "4vh", 
    }     

    return (
      <AdaptiveText textStyle={textStyle} maxvmin={2}>
        {text2}
      </AdaptiveText>        
    ) 

    } else {    
    return <Border>      
      <StyledLogo src={logo} alt="Cehs" draggable="false"/>  
    </Border>    
  }
  
}

const Card = ({children, cardKey, cardsIndex, onDrag, onDragEnd, isDragging, draggedLeft, left, isTarget, data, staggered}) => {
  const firebase = useContext(FirebaseContext)
  const game = useContext(GameContext)

  const handleClick = useCallback(() => {
    const cardRef = firebase.firestore().collection(`games`).doc(game.gameID).collection("deck").doc(cardKey)
    //console.log("card click")  
    //we don't want to send key back to firebase as it ends up as a duplicate
    const removeKey = ({ key, ...rest }) => rest
    cardRef.set({
      ...removeKey(data),
      frontVisible: !data.frontVisible
    })
    .then(res => {
      //console.log("visibility updated");  
    })
    .catch(error => {
      // Handle the error
    })
  }, [firebase, game.gameID, data, cardKey]);

  //console.log('card',cardKey,data, staggered);
  let targetTop;
  if(staggered) {
     targetTop = CARD_MARGIN_TOP + 7*(data.order-1);    
  } else {
    targetTop = CARD_MARGIN_TOP;
  }

  return (
    <Draggable
      key={cardKey}
      id={cardsIndex}
      cardKey={cardKey}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onClick={handleClick}
      order={data.order}
      area={data.area}
      >
      <Frame
        isDragging={isDragging}        
        left={isDragging ? draggedLeft : left}       
        isTarget={isTarget}
        isPrompt={data.prompt}
        targetTop={targetTop}        
        order={data.order}        
        >       
        <CardContent frontVisible={data.frontVisible} text={data.text} isPrompt={data.prompt}/>
      </Frame>
    </Draggable>
  );

};

export default Card;