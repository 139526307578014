import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';
import EditableLabel from './utils/react-inline-editing';

import './PlayerInfo.css';

import { FirebaseContext } from './utils/firebase';
import { GameContext } from './Game';

const PlayerInfoContainer = styled.div`
  position: relative;
  left: 1vw;
  top: 1vh;
  font-size: 1.1vw;
  color: #c9ccd1;
  z-index: 2;
  cursor: text;
`;

const PlayerInfo = () => {
  const game = useContext(GameContext);
  const firebase = useContext(FirebaseContext);
  const analytics = firebase.analytics();
  const handleFocusOut = useCallback((text)=>{

    //console.log("handleChange player name")
    const gameRef = firebase.firestore().collection(`games`).doc(game.gameID)

    const players = game.players;
    players[game.currentUser].name = text;
    
    gameRef.set({    
      players: players        
    }, {merge: true})
    .then(res => {
      //console.log("player name updated");  
      analytics.logEvent('game_player_name_changed', {gameID:game.gameID, playerID: game.currentUser, playerName:text});
    })
    .catch(error => {
      // Handle the error
    })      
  },[game, firebase, analytics])

  if(game && game.currentUser && game.players) {
    const playerData = game.players[game.currentUser];
    if(playerData) {
      //{playerData.name}
      //set {state.name}
  
      // <PlayerInfoContainer>
         
      //   </PlayerInfoContainer>
      return (
        <EditableLabel inputClassName={"input-style"} labelClassName={"label-style"} text={playerData.name} onFocusOut={handleFocusOut}/>
      ) 
    } else {
      return <PlayerInfoContainer>
      saving ...
      </PlayerInfoContainer>
    }
  } else {
    return <PlayerInfoContainer>
      loading ...
    </PlayerInfoContainer>
  }
  
}

export default PlayerInfo;
