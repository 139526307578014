import React, {useState, useCallback, useMemo, useEffect} from 'react';

const POSITION = {x: 0, y: 0};

const Draggable = ({children, id, cardKey, onDrag, onDragEnd, onClick, order, area}) => {
  const [state, setState] = useState({
    isDragging: false,
    origin: POSITION,
    translation: POSITION
  });
	
  const handleMouseDown = useCallback(({clientX, clientY, button}) => {
    //console.log('handleMouseDown', clientX, clientY, button);
    //only left mouse button should be handled
    if(button !== 0) {
      return;
    }
    setState(state => ({
      ...state,
      isDragging: true,
      origin: {x: clientX, y: clientY}
    }));
  }, []);
	
  const handleMouseMove = useCallback(({clientX, clientY, target}) => {
    //console.log('handleMouseMove');
    const translation = {
      x: clientX - state.origin.x, 
      y: clientY - state.origin.y      
    };

    // const position = {
    //   x: clientX, 
    //   y: clientY      
    // };

    const position = {
      x: target.getBoundingClientRect().left, 
      y: target.getBoundingClientRect().top
    };
		
    setState(state => ({
      ...state,
      translation
    }));
		
    onDrag({translation, id, position, cardKey});
  }, [state.origin, onDrag, id, cardKey]);
	
  const handleMouseUp = useCallback(({clientX, clientY, target}) => {

    //console.log('handleMouseUp',clientX, clientY, target.getBoundingClientRect().left, target.getBoundingClientRect().top)
    const position = {
      // x: target.getBoundingClientRect().left, 
      // y: target.getBoundingClientRect().top
      x: clientX,
      y: clientY
    };

    setState(state => ({
      ...state,
      isDragging: false
    }));

    if(state.origin.x === clientX && state.origin.y === clientY) {
      onClick();
    } else {
      onDragEnd(id, position, cardKey);
    }
    
  }, [state.origin, onDragEnd, onClick, id, cardKey]);
	
  useEffect(() => {
    if (state.isDragging) {
      //console.log('event listeners added');
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      
    } else {
      //console.log('event listeners removed');
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      
      setState(state => ({...state, translation: {x: 0, y: 0}}));
    }
    return () => {      
      //console.log('unmounting, event listeners removed');
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

  }, [state.isDragging, handleMouseMove, handleMouseUp]);
	
  const styles = useMemo(() => ({
    cursor: state.isDragging ? '-webkit-grabbing' : '-webkit-grab',
    transform: `translate(${state.translation.x}px, ${state.translation.y}px)`,
    transition: state.isDragging ? 'none' : 'transform 500ms',
    zIndex: state.isDragging ? 999 : order,
    position: state.isDragging ? 'absolute' : 'relative'
  }), [state.isDragging, state.translation, order]);
	
  return (
    <div style={styles} onMouseDown={handleMouseDown}>
      {children}
    </div>
  );
}

export default Draggable;