import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useNavigate } from '@reach/router';
import styled from 'styled-components';

import { FirebaseContext } from './utils/firebase'
import useAuth from './utils/useAuth';
import AvailableDecks from './AvailableDecks';

const Generating = styled.div`
  width:88%;
  height: 20%;
  font-size: 1.2vw;
  margin-bottom: 0.5vh;
  text-align: center;
  color: black;
`;

const InputError = styled.div`
  width:88%;
  height: 20%;
  font-size: 1.2vw;
  margin-bottom: 0.5vh;
  text-align: center;
`;

const InputName = styled.input`
  width:88%;
  height: 20%;
  font-size: 1.6vw;
  margin-bottom: 0.5vh;
  text-align: center;
`;

const InputButton = styled.div`
  width:90%;
  height: 20%;
  font-size: 1.6vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color:#777;
  cursor: pointer;
`;

const GeneratorBox = styled.div`
  left: 35vw;  
  top: 40vh;
  width: 30vw;
  height: 20vh;
  border: 0.2vw solid grey;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Generator = () => {
  const firebase = useContext(FirebaseContext);
  const analytics = firebase.analytics();
  const navigate = useNavigate();
  const [generating, setGenerating] = useState(false);
  const [name, setName] = useState(null);
  const [error, setError] = useState("");
  const [gameID, setGameID] = useState(null);
  const [cardsCopied, setCardsCopied] = useState(0);
  const uid = useAuth();

  const [decks, setDecks] = useState({
    "BaseCAH" : {
      id: "BaseCAH",
      name: "Cehs Pret Cilvēci - pirmējā CAH adaptācija",
      prompts: 100,
      answers: 511,
      base: true,
      selected: true
    },
    "CehsOriginal": {
      id: "CehsOriginal",
      name: "Fērst oridžinal kantent paka",
      prompts: 33,
      answers: 123,
      base: false,
      selected: false,
      replace: true
    },
    "Patiesiba": {
      id: "Patiesiba",
      name: "Patiesības paka",
      prompts: 21,
      answers: 105,
      base: false,
      selected: false,
      replace: true
    },
    "Petenites": {
      id: "Petenites",
      name: "Pincīšu un petenīšu paka (18++)",
      prompts: 27,
      answers: 140,
      base: false,
      selected: false,
      replace: true
    }
  });

  useEffect(() => {    
    if(generating && gameID) {
      const gameRef = firebase.firestore().collection(`games`).doc(gameID)
      const unsubscribe = gameRef.onSnapshot(game => {
        console.log("snapshot to check if deckCopied")
        if(game.data().deckCopied) {
          navigate("/spele/"+gameID, { replace: true });
        }
        
      });
      return () => {
        unsubscribe();
      };  
    }   
  }, [firebase, gameID, generating, navigate]);

  useEffect(() => {    
    if(generating && gameID) {
      const deckRef = firebase.firestore().collection(`games`).doc(gameID).collection('deck')
      const unsubscribe = deckRef.onSnapshot(deck => {
        if(deck) {
          console.log("deck exists for this game", deck.docs.length);
          setCardsCopied(deck.docs.length);
        } else {
          //console.log("deck doesn't exist for this game yet")
        }
        
      });
      return () => {
        unsubscribe();
      };  
    }   
  }, [firebase, gameID, generating]);
  
  
  const generateGame = useCallback(() => {
    if(!name) {
      setError("Lūdzu ievadiet kādu nebūt nosaukumu.")
    } else if(!uid) {
      setError("Mulsinoša problēma ar serveri. Iemēģiniet pogu pēc īsa brīža.")
    }
    else {
      setGenerating(true);
      const gamesCollectionRef = firebase.firestore().collection(`games`);    
      analytics.logEvent('game_generating_start', {name:name, host:uid});
      gamesCollectionRef.add({
        name: name,
        host: uid,
        players: {},
        deckCopied: false,
        decks: decks   
      })
      .then((docRef) => {
        console.log("Game generated",docRef.id);
        setGameID(docRef.id);        
        analytics.logEvent('game_generating_end', {name:name, host:uid, gameID: docRef.id});
      })
      .catch(error => {
        // Handle the error
      })
    }    

  },[uid, name, firebase, setGenerating, setGameID, analytics, decks]);

  const handleOnChange = useCallback(({target}) => {
    setName(target.value);
    if(name) {
      setError("")
    }

    analytics.logEvent('change_game_name', {name:target.value});

  }, [name, setName, analytics]);

  if(!generating) {
    return (
      <div>
        <AvailableDecks decks={decks} setDecks={setDecks}/>     
        <GeneratorBox>           
          <InputError>{error || "Ģenerators gatavs ģenerēt!"}</InputError>
          <InputName type="text" placeholder="Spēles nosaukums, maini mani" onChange={handleOnChange}/>
          <InputButton onClick={generateGame}>Radīt jaunu spēli</InputButton>
        </GeneratorBox>
      </div>
    );
  }  
  else {
    return (
      <GeneratorBox>        
        <Generating>Radām spēli tieši jums ...</Generating>        
        <Generating> {cardsCopied} kārtis radītas.</Generating>
      </GeneratorBox>
    )  
  }  
}


export default Generator;