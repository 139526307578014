import React from 'react';
import styled from 'styled-components';

import Card from './Card';
import Counter from './Counter';

const TARGET_WIDTH = 7.27;
const TARGET_HEIGHT = 17.55;

const TargetContainer = styled.div`
  width: ${TARGET_WIDTH}vw;  
  user-select: none;
  height: ${TARGET_HEIGHT}vh;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15) inset;
  display: flex;
  position: absolute;
  top: ${({top}) => top}vh;
  left: ${({left}) => left}vw;  
  color: #777;
`;

const TargetCaption = styled.div`
  width: ${TARGET_WIDTH}vw;  
  height: 1vh;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(${TARGET_HEIGHT}vh + ${({top}) => top}vh);
  left: ${({left}) => left}vw;
  font-size: 1.1vw;
  text-align: center;
  color: #777;
`;

const Target = React.memo(function Target({children, top, left, caption, cards=[], staggered, onDrag, onDragEnd, areaType}) {
  
  const sortedCards = cards.sort((c1, c2) => c1.order < c2.order ? -1 : 1);
  const lastCards = sortedCards.slice(Math.max(cards.length-5,0)); //no need to render more than 5 cards in one place
  //console.log('target cards',cards, sortedCards, lastCards);
  
  return (
    <div>
      <TargetContainer top={top} left={left}>        
        {
          lastCards.map((card, index)  => {
          // const isDragging = hand.draggedIndex === index;                            
          return (
            <Card
              key={card.key}
              cardKey={card.key}         
              onDrag={onDrag}
              onDragEnd={onDragEnd}                    
              data={card}
              isTarget={true}
              staggered={staggered}        
            />          
          );
        })}
        { staggered ? "" : <Counter isPlayerTarget={areaType==="playerArea"?true:false} number={cards.length}/> }
      </TargetContainer>
      
      <TargetCaption top={top} left={left}>
    
          {caption}
    
      </TargetCaption>
          
    </div>    
  )
  
});

export default Target;